import { useDisplay } from "vuetify"
import { computed } from "vue"

export const useDevice = () => ({
  isMobile: useDisplay().width.value < 600,
  isSmall: useDisplay().smAndUp,
  isMedium: useDisplay().mdAndUp,
  isDesktop: useDisplay().lgAndUp,
})

export function isMobile() {
  const device = useDisplay()

  return computed(() => device.width.value < 600)
}
